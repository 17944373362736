import "./App.css";
import Layout from "./layouts";
import HomePage from "./pages/home";
import { TonConnectButton, TonConnectUIProvider, THEME} from '@tonconnect/ui-react';
import FloatingWalletComponent from "./components/testTon";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const manifestUrl =
"https://catoss.s3.ap-southeast-1.amazonaws.com/telegram/manifest.json";
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
    
  
    <HomePage>

      {/* <TonConnectUIProvider manifestUrl={manifestUrl}>

        <App />

      </TonConnectUIProvider><TonConnectButton /></> */}


    </HomePage>
    </QueryClientProvider>
  );
}
